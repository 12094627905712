import React from "react"
import { graphql } from "gatsby"
import rehypeReact from "rehype-react"
import PathDisplay from "../components/PathDisplay"
import Layout from "../components/layout"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: { "path-display": PathDisplay },
}).Compiler

export default function Template({ data }) {
  const { markdownRemark: post } = data
  return (
    <Layout>
      <main>{renderAst(post.htmlAst)}</main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query MarkdownPageByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        title
      }
    }
  }
`
